import { ref } from 'vue';
import { defineStore } from 'pinia';
import http from '../services/httpService';
import { BandName, IGenerateBandNamesResponse, ISearchArtistsResponse } from '../types/bands';

const useBandNamesStore = defineStore('BandNames', () => {
  // state
  const generatedBandNames = ref<BandName[]>([]);
  const requestInProgress = ref<boolean>(false);
  const networkError = ref<boolean>(false);
  const excludeExactMatchNames = ref<boolean>(false);
  const pinnedBandNames = ref<BandName[]>([]);
  const openBandNamesPanel = ref<boolean>(false);

  // actions
  const getRandomBandNames = async () => {
    requestInProgress.value = true;
    networkError.value = false;
    generatedBandNames.value = [];

    try {
      const { data }: { data: IGenerateBandNamesResponse } = await http.get('/bands/names/generate');
      generatedBandNames.value = data.data
        .map((n) => new BandName(n))
        .filter((b) => !excludeExactMatchNames.value || b.artistsOnSpotify.items.length === 0);
    } catch (error) {
      console.error(error);
      networkError.value = true;
    }

    requestInProgress.value = false;
  };

  const addBandNameToList = (bandname: BandName) => {
    pinnedBandNames.value.push(bandname);
    const idx = generatedBandNames.value.findIndex((b) => b.name === bandname.name);
    if (idx >= 0) {
      generatedBandNames.value.splice(idx, 1);
    }
    openBandNamesPanel.value = true;
  };

  const searchBands = async (query: string): Promise<ISearchArtistsResponse> => {
    networkError.value = false;

    try {
      const { data }: { data: ISearchArtistsResponse } = await http.post('/bands/search', { query });
      return data;
    } catch (error) {
      console.error(error);
      networkError.value = true;
      return {
        data: {
          artists: {
            href: '',
            limit: 25,
            next: '',
            items: [],
            offset: 0,
            total: 0,
          },
        },
      };
    }
  };

  const editBandName = async (updatedBandName: string, uuid: string) => {
    const idx = generatedBandNames.value.findIndex((b) => b.uuid === uuid);
    if (idx === -1) {
      return false;
    }

    const { data } = await searchBands(updatedBandName);
    const {
      items,
    } = data.artists;
    const exactMatchNames = items
      .filter((i) => i.name.trim().toLowerCase() === updatedBandName.trim().toLowerCase());
    const exactMatchIds = exactMatchNames.map((n) => n.id);
    const otherNames = items
      .filter((i) => !exactMatchIds.includes(i.id));
    generatedBandNames.value[idx].name = updatedBandName;
    generatedBandNames.value[idx].artistsOnSpotify.items = exactMatchNames;
    generatedBandNames.value[idx].similarArtistNames.items = otherNames;
    return true;
  };

  return {
    generatedBandNames,
    getRandomBandNames,
    requestInProgress,
    excludeExactMatchNames,
    pinnedBandNames,
    addBandNameToList,
    openBandNamesPanel,
    networkError,
    searchBands,
    editBandName,
  };
}, {
  persist: {
    paths: ['excludeExactMatchNames', 'pinnedBandNames'],
  },
});

export default useBandNamesStore;
