
// eslint-disable-next-line object-curly-newline
import { defineComponent, PropType, ref, watch } from 'vue';
import { BandName, ISpotifyArtist, ISpotifyArtistList } from '@/types/bands';
import { useBandNamesStore } from '@/store';

export default defineComponent({
  props: {
    bandname: {
      type: Object as PropType<BandName>,
      required: true,
    },
  },
  setup(props) {
    // store
    const bandNamesStore = useBandNamesStore();

    // template ref
    const showDetails = ref<boolean>(false);
    const editName = ref<boolean>(false);
    const editableName = ref<string>(props.bandname.name);
    const emitUpdatePid = ref<number>(0);
    const fetchingData = ref<boolean>(false);
    const artistsOnSpotify = ref<ISpotifyArtistList>(
      props.bandname.artistsOnSpotify,
    );
    const similarArtists = ref<ISpotifyArtistList>(
      props.bandname.similarArtistNames,
    );

    // component functions
    const addName = (e: Event) => {
      e.preventDefault();
      bandNamesStore.addBandNameToList(props.bandname);
    };

    const clickArtist = (artist: ISpotifyArtist) => {
      window.open(artist.external_urls.spotify, '_blank');
    };

    // watchers
    watch(editableName, (newValue) => {
      if (emitUpdatePid.value) {
        clearTimeout(emitUpdatePid.value);
      }

      emitUpdatePid.value = setTimeout(() => {
        bandNamesStore.editBandName(newValue, props.bandname.uuid);
      }, 200);
    });

    return {
      showDetails,
      addName,
      clickArtist,
      editName,
      editableName,
      fetchingData,
      similarArtists,
      artistsOnSpotify,
    };
  },
});
