/* eslint-disable camelcase */
import { v4 as uuid } from 'uuid';
import { IPaginatedResponse } from './common';

export interface ISpotifyArtist {
  id: string;
  name: string;
  popularity: number;
  type: 'artist';
  uri: string;
  images: {
    height: number;
    width: number;
    url: string;
  }[];
  external_urls: {
    spotify: string;
  };
  followers: {
    href?: unknown;
    total: number;
  },
  genres: string[];
}

export interface ISpotifyArtistList extends IPaginatedResponse {
  items: ISpotifyArtist[];
}

export interface IBandName {
  uuid?: string;
  name: string;
  artistsOnSpotify: ISpotifyArtistList;
  similarArtistNames: ISpotifyArtistList
}

export class BandName implements IBandName {
  uuid: string; // used for rendering purposes

  name: string;

  artistsOnSpotify: ISpotifyArtistList;

  similarArtistNames: ISpotifyArtistList

  constructor(options: Partial<IBandName>) {
    this.name = options.name || '';
    this.artistsOnSpotify = options.artistsOnSpotify || {
      items: [],
      href: '',
      next: '',
      previous: '',
      limit: 50,
      offset: 0,
      total: 0,
    };
    this.similarArtistNames = options.similarArtistNames || {
      items: [],
      href: '',
      next: '',
      previous: '',
      limit: 50,
      offset: 0,
      total: 0,
    };
    this.uuid = options.uuid || uuid();
  }

  get artistWithSameNameAndMostFollowers(): ISpotifyArtist {
    this.artistsOnSpotify.items.sort((a, b) => a.followers.total - b.followers.total);
    return this.artistsOnSpotify.items.length ? this.artistsOnSpotify.items[0] : {
      id: '',
      name: '',
      popularity: 0,
      type: 'artist',
      uri: '',
      images: [],
      external_urls: {
        spotify: '',
      },
      followers: {
        total: 0,
      },
      genres: [],
    };
  }
}

export interface IGenerateBandNamesResponse {
  data: IBandName[]
}

export interface ISearchArtistsResponse {
  data: {
    artists: ISpotifyArtistList;
  };
}
