
import { defineComponent, watch } from 'vue';
import { useBandNamesStore } from '@/store';
import { storeToRefs } from 'pinia';
import { Toast } from 'vant';
import BandNamesDashboardBandName from '@/components/BandNamesDashboardBandName.vue';
import BandNamesSidePanel from '@/components/BandNamesSidePanel.vue';

export default defineComponent({
  components: {
    BandNamesDashboardBandName,
    BandNamesSidePanel,
  },
  setup() {
    // store
    const bandNamesStore = useBandNamesStore();
    const {
      generatedBandNames,
      requestInProgress,
      excludeExactMatchNames,
      networkError,
      pinnedBandNames,
    } = storeToRefs(bandNamesStore);

    // template functions
    const handleClick = async (e: Event) => {
      e.preventDefault();
      await bandNamesStore.getRandomBandNames();
    };

    // watchers
    watch(networkError, (newValue) => {
      if (newValue) {
        Toast.fail({
          message: 'An issue occurred',
        });
      }
    });

    return {
      generatedBandNames,
      handleClick,
      requestInProgress,
      excludeExactMatchNames,
      networkError,
      pinnedBandNames,
    };
  },
});
