
import { defineComponent } from 'vue';
import BandNamesDashboard from '@/components/BandNamesDashboard.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    BandNamesDashboard,
  },
});
