
import { defineComponent } from 'vue';
import { useBandNamesStore } from '@/store';
import { storeToRefs } from 'pinia';
import { BandName } from '@/types/bands';

export default defineComponent({
  setup() {
    // store
    const bandNamesStore = useBandNamesStore();
    const { pinnedBandNames, openBandNamesPanel } = storeToRefs(bandNamesStore);

    // component methods
    const handleRemoveName = (name: BandName) => {
      pinnedBandNames.value = pinnedBandNames.value.filter(
        // eslint-disable-next-line comma-dangle
        (b) => b.name !== name.name
      );
    };

    return {
      pinnedBandNames,
      openBandNamesPanel,
      handleRemoveName,
    };
  },
});
